img {
    width:400px;
    height:400px;
}
.exercise img {
    display: block;
    margin-top: 10px;
    max-width: 100%;
    height: auto;
}

h1 {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #fff;
}
h2 {
    font-size: 2rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #fff;
}

h3 {
    font-size: 1.5rem;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 1rem;
    color: #fff;
}

body {
    display: flex;
    flex-direction: column;
    align-items: center;
}
